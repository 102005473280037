import React from "react";
import "../styles/singleOrderItem.scss";
import { useSelector } from "react-redux";
import configs from "../../../configs";
import i18next from "i18next";
import { selectRestaurantCurrency } from "../../../pages/main/selectors";
import { useTranslation } from "react-i18next";
import images from "../../../images";
import dish1 from "../../../images/dish1.png";

export default function SingleOrderItem({ data }) {
  const currency = useSelector(selectRestaurantCurrency);
  const { t } = useTranslation();

  return (
    <div className="singleItem">
      <div className="left">
        {data?.food?._id?.imageUrls ? (
          <img
            src={
              data?.food?._id?.imageUrls?.[0]
                ? configs.RESOURCES_BUCKET_URL +
                  "/" +
                  data?.food?._id?.imageUrls?.[0]
                : dish1
            }
            alt=""
          />
        ) : (
          <img src={images.DefaultFood} alt="" />
        )}
      </div>
      <div className="right">
        <div className="food-details">
          <h6>
            {data.food.name[i18next.language || "en"]}
            {data?.food?.priceVariant?.name &&
              ` - ${
                data.food.priceVariant.name[i18next.language] ||
                data.food.priceVariant.name["en"]
              }`}
          </h6>
          <h6>
            {currency}{" "}
            {data?.food?.total
              ? Number.parseFloat(data.food.total).toFixed(2)
              : null}
          </h6>
        </div>
        <p>
          {data.food.count} {t("cart.items")}
        </p>
      </div>
    </div>
  );
}
