/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "../styles/cartPaymentType.scss";
import { MODAL_STATUS, PAYMENT_METHODS } from "../constants";
import {
  selectPaymentmethod,
  selectCurrentUserId,
  selectPaymentOptions,
  selectCartData,
  selectRestaurantId,
  selectOrderSavingInProgress,
  selectStripePaymentIntentStatus,
  selectStripePaymentChargeId,
  selectTable,
  selectLocationDetails,
} from "../../../pages/main/selectors";
import { useSelector, useDispatch } from "react-redux";
import { useMainSlice } from "../../../pages/main/actions";
import { useTranslation } from "react-i18next";
import StripeCheckoutForm from "./StripeCheckoutForm";
import PaypalButton from "../../PaypalButton";
import { getUuid } from "../../../socket/UuidService";
import configs from "../../../configs";
import queryString from "query-string";
import ButtonCirculerLoader from "../../ButtonCirculerLoader";
import { BsCreditCardFill, BsPaypal, BsCashCoin } from "react-icons/bs";
import { FaRegHandshake } from "react-icons/fa";
import CustomOptionsList from "./CustomOptionsList";

export default function CartPaymentType(props) {
  const paymentMethod = useSelector(selectPaymentmethod);
  const paymentOptions = useSelector(selectPaymentOptions);
  const currentUserId = useSelector(selectCurrentUserId);
  const cartData = useSelector(selectCartData);
  const restaurantId = useSelector(selectRestaurantId);
  const stripePaymentChargeId = useSelector(selectStripePaymentChargeId);
  const orderSavingInProgress = useSelector(selectOrderSavingInProgress);
  const selectedTable = useSelector(selectTable);
  // const { storeId } = useParams();
  const dispatch = useDispatch();
  const { actions } = useMainSlice();
  const { table } = queryString.parse(window.location.search);
  const stripePaymentIntentStatus = useSelector(
    selectStripePaymentIntentStatus
  );
  const locationDetails = useSelector(selectLocationDetails);
  // const { storeId } = useParams();
  const storeId = localStorage.getItem(`store-${configs.APP_DOMAIN}`);
  const { t } = useTranslation();

  const onBack = () => {
    props.setModalStatus(MODAL_STATUS.PLACE_ORDER);
  };
  const setPaymentMethod = (methord) => {
    dispatch(actions.setPaymentMethod(methord));
  };
  const onSuccess = ({ orderId, _paymentMethod = paymentMethod }) => {
    const _store = storeId.split("?")[0];
    if (_store && restaurantId) {
      dispatch(
        actions.saveOrder({
          ...cartData,
          paymentMethod: _paymentMethod,
          uuid: getUuid(),
          ...(currentUserId ? { customerId: currentUserId } : {}),
          restaurantId,
          ...(table ? { table: selectedTable } : {}),
          storeId: _store,
          paid: _paymentMethod
            ? _paymentMethod === PAYMENT_METHODS.STRIPE ||
              _paymentMethod === PAYMENT_METHODS.PAYPAL
              ? true
              : false
            : false,
          ...(orderId ? { paymentReference: orderId } : {}),
          location: locationDetails?.data,
        })
      );
    }
  };
  useEffect(() => {
    if (stripePaymentIntentStatus && cartData.sub_total > 0) {
      switch (stripePaymentIntentStatus) {
        case "succeeded":
          onSuccess({
            orderId: stripePaymentChargeId,
            _paymentMethod: PAYMENT_METHODS.STRIPE,
          });
          break;
        case "processing":
          console.log("payment still processing");
          break;
        default:
          console.log("Something went wrong");
          break;
      }
    }
  }, [stripePaymentIntentStatus, cartData]);

  return (
    <div className="cart-payment-type-wrapper">
      <h4>{t("cart.payment_methord")}</h4>
      <p>{t("cart.choose_payment_method")}</p>
      <div className="cart-payment-row">
        {Object.keys(paymentOptions).map((key, index) => {
          switch (key) {
            case PAYMENT_METHODS.PAYPAL:
              return (
                <>
                  {paymentOptions?.paypal.enable && (
                    <div
                      className={`${
                        paymentMethod === PAYMENT_METHODS.PAYPAL
                          ? "selected"
                          : ""
                      } cart-payment-type paypal`}
                      onClick={() =>
                        !orderSavingInProgress &&
                        setPaymentMethod(PAYMENT_METHODS.PAYPAL)
                      }
                    >
                      {/* <img src={payPal} alt="" /> */}
                      <BsPaypal size={20} />
                      <h6>Paypal</h6>
                    </div>
                  )}
                </>
              );
            case PAYMENT_METHODS.STRIPE:
              return (
                <>
                  {paymentOptions?.stripe.enable && (
                    <div
                      className={`${
                        paymentMethod === PAYMENT_METHODS.STRIPE
                          ? "selected"
                          : ""
                      } cart-payment-type stripe`}
                      onClick={() =>
                        !orderSavingInProgress &&
                        setPaymentMethod(PAYMENT_METHODS.STRIPE)
                      }
                    >
                      <BsCreditCardFill size={20} />
                      <h6>Card</h6>
                      {/* <img src={stripe} alt="" /> */}
                    </div>
                  )}
                </>
              );
            case PAYMENT_METHODS.CASH:
              return (
                <>
                  {paymentOptions?.cash.enable && (
                    <div
                      className={`${
                        paymentMethod === PAYMENT_METHODS.CASH ? "selected" : ""
                      } cart-payment-type cash`}
                      onClick={() =>
                        !orderSavingInProgress &&
                        setPaymentMethod(PAYMENT_METHODS.CASH)
                      }
                    >
                      <BsCashCoin size={20} />
                      <h6>Cash</h6>
                    </div>
                  )}
                </>
              );
            case PAYMENT_METHODS.CUSTOM_OPTIONS:
              return (
                <>
                  {paymentOptions?.customOptions.enable && (
                    <div
                      className={`${
                        paymentMethod === PAYMENT_METHODS.CUSTOM_OPTIONS
                          ? "selected"
                          : ""
                      } cart-payment-type cash`}
                      onClick={() =>
                        !orderSavingInProgress &&
                        setPaymentMethod(PAYMENT_METHODS.CUSTOM_OPTIONS)
                      }
                    >
                      <FaRegHandshake size={20} />
                      <h6>{t("cart.custom-options")}</h6>
                    </div>
                  )}
                </>
              );
            default:
              return null;
          }
        })}
      </div>
      {paymentMethod === PAYMENT_METHODS.STRIPE ? (
        <StripeCheckoutForm />
      ) : paymentMethod === PAYMENT_METHODS.PAYPAL ? (
        <PaypalButton onSuccess={({ orderId }) => onSuccess({ orderId })} />
      ) : paymentMethod === PAYMENT_METHODS.CUSTOM_OPTIONS ? (
        <CustomOptionsList
          onSuccess={(_paymentMethod) =>
            onSuccess({ orderId: undefined, _paymentMethod })
          }
          onBack={onBack}
        />
      ) : (
        <div className="button-wrapper">
          <button className="cancel-button" onClick={onBack}>
            {t("cart.back")}
          </button>
          <button
            className="main-button"
            disabled={orderSavingInProgress}
            onClick={() => onSuccess({ orderId: undefined })}
          >
            {t("cart.confirm")}{" "}
            {orderSavingInProgress && <ButtonCirculerLoader />}
          </button>
        </div>
      )}
    </div>
  );
}
