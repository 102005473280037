import { createSlice } from "../../Redux/toolkit";
import { useInjectReducer, useInjectSaga } from "../../Redux/redux-injectors";
import mainSaga from "./saga";
import configs from "../../configs";
import { PAYMENT_METHODS } from "../../components/CheckoutModal/constants";
import { Store } from "react-notifications-component";
import DiscountNotificationCard from "../../components/DiscountNotificationCard";
import {
  addToCartLoyvers,
  removeCartLoyvers,
} from "./miniApps/loyvers/loyvers";
import { removeCartDefault } from "./miniApps/default/default";

const default_cart = {
  items: [],
  discount: 0.0,
  promotions: [],
  sub_total: 0.0,
  total: 0.0,
  tip: 0.0,
  tax_included: 0.0,
  tax_not_included: 0.0,
};

export const initialState = {
  loading: false,
  restaurantData: null,
  errorMessage: null,
  loginLoading: false,
  currentUser: null,
  subscription: "active",
  cartData: default_cart,
  tipAmount: 0.0,
  cartItemCount: 0,
  authenticated: false,
  paymentMethord: PAYMENT_METHODS.CASH,
  orderSavingInProgress: false,
  orderEditingInProgress: false,
  ordersInProgress: [],
  orderSavingSucceeded: false,
  orderEditingSucceeded: false,
  selectedStoreId: null,
  resetPasswordLinkSent: false,
  resetPasswordSucceeded: false,
  promotions: [],
  stripePaymentIntentClientSecret: null,
  stripeCheckoutInProgress: false,
  paymentIntentDetails: null,
  fonts: null,
  selectedInprogressOrder: null,
  wheiteLabelEnabled: false,
  hotActions: {
    loading: false,
    data: [],
    error: null,
  },
  locationDetails: {
    data: null,
    error: null,
  },
};

const cartData = localStorage.getItem(`cart-${configs.APP_DOMAIN}`);
if (cartData) {
  try {
    const _data = JSON.parse(cartData);
    if (_data && _data.items) {
      initialState.cartData = _data;
      initialState.cartItemCount = _data.items?.length;
    } else {
      localStorage.removeItem(`cart-${configs.APP_DOMAIN}`);
    }
  } catch (error) {
    localStorage.removeItem(`cart-${configs.APP_DOMAIN}`);
  }
}

export const slice = createSlice({
  name: "main",
  initialState,
  reducers: {
    getRestaurantData(state, action) {
      state.loading = true;
    },
    getRestaurantDataSucceeded(state, action) {
      state.loading = false;
      state.restaurantData = action.payload;
    },
    setWhiteLabelEnabledStatus(state, action) {
      state.wheiteLabelEnabled = action.payload;
    },
    getRestaurantDataFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    register(state, action) {
      state.loginLoading = true;
    },
    registerSucceeded(state, action) {
      state.loginLoading = false;
      state.errorMessage = null;
      state.currentUser = action.payload.user;
    },
    registerFailed(state, action) {
      state.loginLoading = false;
      state.errorMessage = action.payload;
    },
    login(state, action) {
      state.loginLoading = true;
    },
    loginSucceeded(state, action) {
      state.loginLoading = false;
      state.errorMessage = null;
      state.currentUser = action.payload.user;
    },
    loginFailed(state, action) {
      state.loginLoading = false;
      state.errorMessage = action.payload;
    },
    reSetCartData(state) {
      state.cartData = default_cart;
      state.cartItemCount = 0;
      localStorage.setItem(
        `cart-${configs.APP_DOMAIN}`,
        JSON.stringify(state.cartData)
      );
    },
    addCartData(state, action) {
      let updatedData = {};

      switch (action.payload.miniAppId) {
        case "LOYVERSE":
          updatedData = addToCartLoyvers(state, action.payload);
          break;
        default:
          updatedData = addToCartLoyvers(state, action.payload);
        // updatedData = addToCartDefault(state, action.payload);
      }

      // notify customer
      updatedData?.promotions?.forEach((element) => {
        if (
          state.cartData?.promotions?.findIndex((e) => e.id === element.id) ===
          -1
        ) {
          Store.addNotification({
            type: "success",
            container: "bottom-center",
            dismiss: {
              duration: 1000,
            },
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            content: () => {
              return <DiscountNotificationCard data={element} />;
            },
          });
        }
      });
      //update state
      state.cartItemCount = updatedData.cartItemCount;
      state.cartData = {
        items: updatedData.items,
        discount: updatedData.discount,
        sub_total: updatedData.sub_total,
        total: updatedData.total,
        tip: updatedData.tip,
        promotions: updatedData.promotions,
        tax_included: updatedData.tax_included,
        tax_not_included: updatedData.tax_not_included,
      };

      localStorage.setItem(
        `cart-${configs.APP_DOMAIN}`,
        JSON.stringify(state.cartData)
      );
    },
    removeCartItem(state, action) {
      //get the miniAppId
      const removedItem = state?.cartData?.items?.[action.payload];
      const store = state?.restaurantData?.storeIds?.find(
        (store) => store?._id === removedItem?.selectedStoreId
      );
      const { miniAppId } = store?.menuId?.externalPosStoreId || {};
      let updatedData = {};

      switch (miniAppId) {
        case "LOYVERSE":
          updatedData = removeCartLoyvers(state, action.payload);
          break;
        default:
          updatedData = removeCartDefault(state, action.payload);
      }

      //update state
      state.cartItemCount = updatedData.cartItemCount;
      state.cartData = {
        items: updatedData.items,
        discount: updatedData.discount,
        sub_total: updatedData.sub_total,
        total: updatedData.total,
        tip: updatedData.tip,
        promotions: updatedData.promotions,
        tax_included: updatedData.tax_included,
        tax_not_included: updatedData.tax_not_included,
      };

      localStorage.setItem(
        `cart-${configs.APP_DOMAIN}`,
        JSON.stringify(state.cartData)
      );
    },
    clearCurrentUser(state) {
      state.currentUser = null;
      state.authenticated = false;
    },
    getCurrentUser(state, action) {
      state.loginLoading = true;
    },
    getCurrentUserSucceeded(state, action) {
      state.loginLoading = false;
      state.errorMessage = null;
      state.currentUser = action.payload;
    },
    getCurrentUserFailed(state, action) {
      state.loginLoading = false;
      state.errorMessage = action.payload;
    },
    setAuthenticatedStatus(state, action) {
      state.authenticated = action.payload;
    },
    setPaymentMethod(state, action) {
      state.paymentMethord = action.payload;
      state.errorMessage = null;
      state.stripePaymentIntentClientSecret = null;
    },
    saveOrder(state, action) {
      state.orderSavingInProgress = true;
    },
    saveOrderSucceeded(state, action) {
      state.orderSavingInProgress = false;
      state.orderSavingSucceeded = true;
      const table = action.payload.table;
      if (table) {
        if (table.enableOrderProgress) {
          state.ordersInProgress = [...state.ordersInProgress, action.payload];
        } else if (table.enableOrderProgress === undefined) {
          state.ordersInProgress = [...state.ordersInProgress, action.payload];
        }
      } else {
        state.ordersInProgress = [...state.ordersInProgress, action.payload];
      }

      state.paymentIntentDetails = null;
      state.stripePaymentIntentClientSecret = null;
    },
    saveOrderFailed(state, action) {
      state.orderSavingInProgress = false;
      state.errorMessage = action.payload;
    },
    editOrder(state, action) {
      state.orderEditingInProgress = true;
    },
    editOrderSucceeded(state, action) {
      state.orderEditingInProgress = false;
      state.orderEditingSucceeded = true;
      let temp_orders = [...state.ordersInProgress];
      let canceledOrerIndex = temp_orders.indexOf(
        temp_orders.find((order) => order._id === action.payload._id)
      );

      if (canceledOrerIndex >= 0) {
        temp_orders.splice(canceledOrerIndex, 1);
      }
      state.ordersInProgress = temp_orders;
    },
    editOrderFailed(state, action) {
      state.orderEditingInProgress = false;
      state.orderEditingSucceeded = false;
      state.errorMessage = action.payload;
    },
    clearEditingSucceeded(state, action) {
      state.orderEditingSucceeded = false;
    },
    setOrderStatus(state, action) {
      const { status, orderId } = action.payload;
      let order = [...state.ordersInProgress];
      order.map((item) => {
        if (item._id === orderId) {
          item.status = status;
        }
        return null;
      });
      state.ordersInProgress = order;
    },
    clearCart(state) {
      state.cartData = default_cart;
      localStorage.setItem(`cart-${configs.APP_DOMAIN}`, default_cart);
      state.orderSavingSucceeded = false;
      state.cartItemCount = 0;
    },
    fetchExistingOrders(state) {},
    fetchExistingOrdersSucceeded(state, action) {
      state.ordersInProgress = [...state.ordersInProgress, ...action.payload];
    },
    fetchExistingOrdersFailed(state) {},
    updateOrderStatus(state, action) {
      let temp = [...state.ordersInProgress];
      let order = temp.filter((item) => item._id === action.payload.orderId);
      if (order.length > 0) {
        order[0].status = action.payload.status;
      }
      state.ordersInProgress = temp;
    },
    setSelectedStoreId(state, action) {
      state.selectedStoreId = action.payload;
    },
    clearErrorMessage(state) {
      state.errorMessage = null;
    },
    sendResetPasswordLink(state, action) {
      state.loginLoading = true;
      state.errorMessage = null;
      state.resetPasswordLinkSent = false;
    },
    sendResetPasswordLinkSucceeded(state, action) {
      state.loginLoading = false;
      state.resetPasswordLinkSent = true;
    },
    sendResetPasswordLinkFailed(state, action) {
      state.loginLoading = false;
      state.errorMessage = action.payload;
      state.resetPasswordLinkSent = false;
    },
    resetPassword(state, action) {
      state.loginLoading = true;
      state.errorMessage = null;
      state.resetPasswordSucceeded = false;
    },
    resetPasswordSucceeded(state, action) {
      state.loginLoading = false;
      state.resetPasswordSucceeded = true;
    },
    resetPasswordFailed(state, action) {
      state.loginLoading = false;
      state.errorMessage = action.payload;
      state.resetPasswordSucceeded = false;
    },
    setTipAmount(state, action) {
      let exisiting_tip = state.cartData.tip || 0.0;
      state.cartData.total =
        Math.round(
          (state.cartData.total - exisiting_tip + action.payload) * 100
        ) / 100;
      state.cartData.tip = Math.round(action.payload * 100) / 100;
      localStorage.setItem(
        `cart-${configs.APP_DOMAIN}`,
        JSON.stringify(state.cartData)
      );
    },
    getCurrentPromotions(state, action) {
      state.loading = true;
    },
    getCurrentPromotionsSucceeded(state, action) {
      state.loading = false;
      state.promotions = action.payload;
    },
    getCurrentPromotionsFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    getSubscriptionStatus(state, action) {
      state.loading = true;
    },
    getSubscriptionStatusSucceeded(state, action) {
      state.loading = false;
      if (
        action.payload?.status &&
        (action.payload?.status === "active" ||
          action.payload?.status === "trialing")
      ) {
        state.subscription = "active";
      } else {
        state.subscription = "blocked";
      }
    },
    getSubscriptionStatusFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    getPaymentIntentDetails(state, action) {
      state.errorMessage = null;
    },
    getPaymentIntentDetailsSucceeded(state, action) {
      state.paymentIntentDetails = action.payload;
    },
    getPaymentIntentDetailsFailed(state, action) {
      state.errorMessage = action.payload;
      state.paymentIntentDetails = null;
    },
    getStripeCheckoutSession(state, action) {
      state.stripeCheckoutInProgress = true;
      state.errorMessage = null;
    },
    getStripeCheckoutSessionSucceeded(state, action) {
      state.stripeCheckoutInProgress = false;
      state.stripePaymentIntentClientSecret = action.payload.client_secret;
    },
    getStripeCheckoutSessionFailed(state, action) {
      state.stripeCheckoutInProgress = false;
      state.errorMessage = action.payload;
      state.stripePaymentIntentClientSecret = null;
    },
    getFonts(state, action) {},
    getFontsSucceeded(state, action) {
      state.fonts = action.payload;
    },
    getFontsFailed(state, action) {
      state.errorMessage = action.payload;
    },
    setSelectedInprogressOrder(state, action) {
      state.selectedInprogressOrder = action.payload;
    },

    receiveHotActions(state) {
      state.hotActions.loading = true;
    },
    receiveHotActionsSucceeded(state, action) {
      state.hotActions.loading = false;
      state.hotActions.data = action.payload;
    },
    receiveHotActionsFailed(state, action) {
      state.hotActions.loading = false;
      state.hotActions.error = action.payload;
    },

    setLocationDetailsSucceeded(state, action) {
      state.locationDetails.data = action.payload;
    },
    setLocationDetailsFailed(state, action) {
      state.locationDetails.error = action.payload;
    },
  },
});

export const { actions: mainActions } = slice;

export const useMainSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: mainSaga });
  return { actions: slice.actions };
};
